<template>
  <div class="main-layout select-project">
    <h1>{{ heading }}</h1>
    <div id="project-optionset">
      <div class="project" v-for="(item, index) in projects" v-bind:key="index">
        <h2
          :id="'project-' + item._id"
          :style="'background-color:' + colors[index % 4]"
          v-bind:key="hover"
        >
          <a
            href="#"
            @mousedown.prevent="selectProject(item)"
            v-if="!projectId"
            >{{ item.name }}</a
          >
          <div v-if="projectId == item._id">Loading...</div>
        </h2>
      </div>
    </div>
    <main-layout></main-layout>
  </div>
</template>

<script>
import MainLayout from "../components/MainLayout";
// import {currentUser} from '../store'
export default {
  components: {
    MainLayout,
  },
  data: function () {
    return {
      heading: "Select project",
      projects: [],
      projectId: null,
      hover: 0,
      colors: ["#D6EEDF", "#FDEFE8", "#FDF8E1", "rgba(39, 128, 186, 0.2)"],
    };
  },
  created: function () {
    this.$store
      .dispatch("loadProjects")
      .then((projects) => {
        this.projects = projects;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    selectProject: function (project) {
      if (this.projectId) return;

      this.projectId = project.id;
      this.$store.commit("setCurrentProject", project);
      this.$store.commit("setCurrentPage", "/SelectScenario");
      this.$router.push({
        path: "/SelectScenario",
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/game-components.scss";

.select-project {
  padding-top: 103px;
  h1 {
    margin-bottom: 40px;
  }

  .project {
    & > h2 {
      width: 980px;
      margin: 10px auto;
      height: 32px;
      border-radius: 15px;
      padding: 30px 46px;
      justify-content: space-between;
      text-align: center;
      a {
        color: inherit;
        display: block;
      }
    }
  }
  #project-optionset {
    padding-bottom: 220px;
  }
}
</style>
